<template>
  <template v-if="isUnderspendActive">
    <span v-if="type === 'flag'" class="position-relative">
      <span
        v-bind="$attrs"
        class="text-danger xs offset-flag"
        v-p-tooltip.top="'Underspend Mitigation is Active'">
        <fluency-icon type="flag" />
      </span>
    </span>
    <t-alert v-else-if="type === 'alert'"
             v-bind="$attrs"
             show
             variant="warning">
      <fluency-icon class="text-danger pr-3" type="flag" />
      Underspend Mitigation is Active
    </t-alert>
  </template>
</template>

<script setup>
const nuxtApp = useNuxtApp()
const props = defineProps({
  budgetPlan: Object,
  type: {
    type: String,
    default: 'flag',
    validator: (val) => ['alert', 'flag'].includes(val?.toLowerCase())
  }
})

const underspendStatus = (budgetPlan) => {
  let underspendStatusVal = budgetPlan?.budgetUnderspendStatus
  if (!underspendStatusVal) {
    const accountBudget = nuxtApp.$store.getters.activeAccountBudgets.find(budget => budget.budgetPlanId === budgetPlan?.budgetPlanId)
    underspendStatusVal = accountBudget?.budgetUnderspendStatus
  }
  return underspendStatusVal
}

const underspendProtectionIsActive = (budgetPlan) => {
  const status = underspendStatus(budgetPlan)
  if (!status) return false
  const anyUnderspendIsActive = Object.keys(status).filter(key => key.startsWith('has'))
    .some(underspendKey => status[underspendKey])
  return anyUnderspendIsActive
}

const isUnderspendActive = underspendProtectionIsActive(props.budgetPlan)

</script>

<style lang='scss' scoped>
  .offset-flag {
    position: absolute;
    top: -6px;
    right: -15px;
  }
</style>
